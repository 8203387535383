import React from "react"

import Layout from "../../components/layout"
import SEO from "../../components/seo"
import imgVehicle from "../../assets/images/vehicle/JUKE_F15-A1-min.png"

class MyContracts extends React.Component {

  render() {
    return (
      <Layout>
        <SEO title="My Contracts" />

        <h2 className="mb-4"><a href="./login" className="link-back grey-border">Back to your timeline</a></h2>

        <div className="white-container mb-5">

          <div className="header-vehicle bg-dark-red d-md-flex mb-4">

            <div className="flex-md-grow-1 align-items-center d-md-flex">
              <img src={imgVehicle} alt="My Nissan Juke" className="img-fluid mr-md-4" width="80" />
              <div>
                <h1 className="d-inline-block mb-0">JUKE</h1> <div className="fs-20 d-inline-block">AB-344-CA</div>
              </div>
            </div>

          </div>





          <div className="row">
            <div className="col-md-6 mb-4">
              <h2 className="with-border"><span className="icon-extension-garanti fs-30 align-middle"></span> Warranty</h2>

              <div className="accordion" id="contracts-1">

                  <div className="contracts-container">

                    <div className="item">

                      <a href="#" className="btn-collapse" data-toggle="collapse" data-target="#collapse-contracts-1-1" aria-expanded="true" aria-controls="collapse-contracts-1-1">
                        <span>-</span>
                      </a>

                      <h3 className="mb-2">Nissan warranty<br />24 Months - End 02/09/2018</h3>

                      <p className="status">Statut: <span className="dark-red">Over &#11044;</span></p>

                      <div id="collapse-contracts-1-1" className="collapse show">
                        <span className="icon-download float-right"></span>
                        You can improve your status by the witcher
                      </div>

                    </div>

                    <div className="item">

                      <a href="#" className="btn-collapse" data-toggle="collapse" data-target="#collapse-contracts-1-2" aria-expanded="true" aria-controls="collapse-contracts-1-2">
                        <span>-</span>
                      </a>

                      <h3 className="mb-2">Nissan warranty<br />24 Months - End 02/09/2018</h3>

                      <p className="status">Statut: <span className="blue-active">Active &#11044;</span></p>

                      <div id="collapse-contracts-1-2" className="collapse show">
                        <span className="icon-download float-right"></span>
                        You can improve your status by the witcher
                      </div>

                    </div>

                    <div className="item">

                      <a href="#" className="btn-collapse" data-toggle="collapse" data-target="#collapse-contracts-1-3" aria-expanded="true" aria-controls="collapse-contracts-1-3">
                        <span>-</span>
                      </a>

                      <h3 className="mb-2">Nissan warranty<br />24 Months - End 02/09/2018</h3>

                      <p className="status">Statut: <span className="blue-active">Active &#11044;</span></p>

                      <div id="collapse-contracts-1-3" className="collapse show">
                        <span className="icon-download float-right"></span>
                        You can improve your status by the witcher
                      </div>

                    </div>

                  </div>

              </div>

            </div>

            <div className="col-md-6 mb-4">

              <h2 className="with-border"><span className="icon-recall fs-30 align-middle"></span> Maintenance</h2>


              <div className="accordion" id="contracts-2">

                <div className="contracts-container">

                  <div className="item">

                    <a href="#" className="btn-collapse" data-toggle="collapse" data-target="#collapse-contracts-2-1" aria-expanded="true" aria-controls="collapse-contracts-2-1">
                      <span>-</span>
                    </a>

                    <h3 className="mb-2">Nissan warranty<br />24 Months - End 02/09/2018</h3>

                    <p className="status">Statut: <span className="dark-red">Over &#11044;</span></p>

                    <div id="collapse-contracts-2-1" className="collapse show">
                      <span className="icon-download float-right"></span>
                      You can improve your status by the witcher
                    </div>

                  </div>

                  <div className="item">

                    <a href="#" className="btn-collapse" data-toggle="collapse" data-target="#collapse-contracts-2-2" aria-expanded="true" aria-controls="collapse-contracts-2-2">
                      <span>-</span>
                    </a>

                    <h3 className="mb-2">Nissan warranty<br />24 Months - End 02/09/2018</h3>

                    <p className="status">Statut: <span className="blue-active">Active &#11044;</span></p>

                    <div id="collapse-contracts-2-2" className="collapse show">
                      <span className="icon-download float-right"></span>
                      You can improve your status by the witcher
                    </div>

                  </div>

                  <div className="item">

                    <a href="#" className="btn-collapse" data-toggle="collapse" data-target="#collapse-contracts-2-3" aria-expanded="true" aria-controls="collapse-contracts-2-3">
                      <span>-</span>
                    </a>

                    <h3 className="mb-2">Nissan warranty<br />24 Months - End 02/09/2018</h3>

                    <p className="status">Statut: <span className="blue-active">Active &#11044;</span></p>

                    <div id="collapse-contracts-2-3" className="collapse show">
                      <span className="icon-download float-right"></span>
                      You can improve your status by the witcher
                    </div>

                  </div>

                </div>

              </div>

            </div>
          </div>

          <div className="col-md-6 mb-4">

            <h2 className="with-border"><span className="icon-circle fs-20 align-middle">+</span> Others</h2>


            <div className="accordion" id="contracts-3">

              <div className="contracts-container">

                <div className="item">

                  <a href="#" className="btn-collapse" data-toggle="collapse" data-target="#collapse-contracts-3-1" aria-expanded="true" aria-controls="collapse-contracts-3-1">
                    <span>-</span>
                  </a>

                  <h3 className="mb-2">Nissan warranty<br />24 Months - End 02/09/2018</h3>

                  <p className="status">Statut: <span className="dark-red">Over &#11044;</span></p>

                  <div id="collapse-contracts-3-1" className="collapse show">
                    <span className="icon-download float-right"></span>
                    You can improve your status by the witcher
                  </div>

                </div>

                <div className="item">

                  <a href="#" className="btn-collapse" data-toggle="collapse" data-target="#collapse-contracts-3-2" aria-expanded="true" aria-controls="collapse-contracts-3-2">
                    <span>-</span>
                  </a>

                  <h3 className="mb-2">Nissan warranty<br />24 Months - End 02/09/2018</h3>

                  <p className="status">Statut: <span className="blue-active">Active &#11044;</span></p>

                  <div id="collapse-contracts-3-2" className="collapse show">
                    <span className="icon-download float-right"></span>
                    You can improve your status by the witcher
                  </div>

                </div>

                <div className="item">

                  <a href="#" className="btn-collapse" data-toggle="collapse" data-target="#collapse-contracts-3-3" aria-expanded="true" aria-controls="collapse-contracts-3-3">
                    <span>-</span>
                  </a>

                  <h3 className="mb-2">Nissan warranty<br />24 Months - End 02/09/2018</h3>

                  <p className="status">Statut: <span className="blue-active">Active &#11044;</span></p>

                  <div id="collapse-contracts-3-3" className="collapse show">
                    <span className="icon-download float-right"></span>
                    You can improve your status by the witcher
                  </div>

                </div>

              </div>

            </div>

          </div>


        </div>

      </Layout>
    )
  }
}

export default MyContracts
